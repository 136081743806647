if ($('.page-home').length) {
    var swiper__headerHome = new Swiper('.swiper__headerHome', {
        speed: 400,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        }
    });
}